.partner-item {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
