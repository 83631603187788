@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
 /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',''
    sans-serif;
    */

  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Nexa Bold";
  src: local("Nexa Bold"),
    url("./assets/fonts/Nexa\ Bold.otf") format("opentype");
}
@font-face {
  font-family: "Nexa Light";
  src: local("Nexa Light"),
    url("./assets/fonts/Nexa\ Light.otf") format("opentype");
}
@font-face {
  font-family: "Nexa Regular";
  src: local("Nexa Regular"),
    url("./assets/fonts/Nexa\ Regular.otf") format("opentype");
}
@font-face {
  font-family: nova-light;
  src: url("./assets/fonts/Proxima-Nova-Alt-Light.otf");
}

@font-face {
  font-family: nova-bold;
  src: url("./assets/fonts/Proxima-Nova-Alt-Bold.otf");
}

@font-face {
  font-family: nova-thin;
  src: url("./assets/fonts/Proxima-Nova-Alt-Thin.otf");
}
.main-btn{
  width:"auto"!important
}
.main-btnn{
  width:"auto"!important
}