.customButton{

    .myButton{
        background-color: #f45a2f ;
        color: white;
        border: 2px solid #f45a2f;
        padding: 7px 10px 8px 10px;
        transition: 1s;
        margin-bottom:5%;
        height:100%;
    }:hover{
        background-color:rgb(83, 20, 1) ;
        border: 2px solid var(--web-theme);
        transition: 1s;
        
    }

    
}