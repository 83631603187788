.protected-header {
  height: 80px;
  /* background-color: white; */
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  align-items: center;
}

.admin-header-left {
  display: flex;
  align-items: center;
}
.admin-header-text {
  font-family: nova-bold;
  font-size: 28px;
}
.admin-header-left svg {
  font-size: 24px;
  cursor: pointer;
  color: #f45a2f;
}
.admin-header-right {
  display: flex;
}
.admin-user-icon svg {
  font-size: 32px;
  cursor: pointer;
  color: #f45a2f;
}
.header-search {
  margin-right: 34px;
  display: flex;
  align-items: center;
}

.header-search svg {
  cursor: pointer;
}

.hideInput {
  background-color: #ccc;
  border: none;
  transform: scaleX(0);
  transition: all 500ms ease-in-out;
  transform-origin: right;
  width: 0;
}

.showInput {
  transform: scaleX(1);
  transform-origin: right;
  width: 100px !important;
}
@media (max-width: 992px) {
  .admin-toggle {
    display: flex!important;
  }
}
.admin-toggle{
  display: none;
}