.donationform{
    text-align: start;
    margin-bottom: 30px;
    width: 100%;

    h4{
        color: var(--web-theme);
        font-weight: 700;
        margin-bottom: 20px;
    }

    .donationSection{
        padding: 20px 0px;
        
        .inputRow{
            margin-bottom: 10px;
            
           
        }
        .title{
            font-size: 13px;
            font-weight: 700;
        }
        input{
            width: 90%;
        }
        input[type=text]:focus{
            outline: none;  
            border: 1.5px solid var(--web-theme);  
            border-radius: 3px;
          }
          input[type=email]:focus{
            outline: none;  
            border: 1.5px solid var(--web-theme);  
            border-radius: 3px;
          }
          input[type=country]:focus{
            outline: none;  
            border: 1.5px solid var(--web-theme);   
            border-radius: 3px;
          }
          input[type=date]:focus{
            outline: none;  
            border: 1.5px solid var(--web-theme);   
            border-radius: 3px;
          }
          
    }

    .Checkbox{
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
       
        input{
            margin: 5px;
        }
        label{
            font-size: 12px;
        }
        
    }
   
    .infoContainer{
        margin-top: 20px;
        margin-bottom:20px;
        border: 1px dotted black;
        padding: 20px 10px;
        p{
            font-size: 13px;

            span{
                font-size: 13px;
                font-weight: bold;
            }
        }
    }

    .contactContainer{
        margin-top: 20px;
        p{
            font-size: 13px;
            font-weight: bold;
            span{
                font-size: 12px;
                font-weight: 500;
                
            }
        }
    }
    
}

// .esewa-panel{
//     // background-color:white;
//     border-radius:10%
// }
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    // height:41vh;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    // border-radius: 0%;
    outline: 0;
    justify-content: center;
    align-items: center;
    margin:auto;
}
.image{
    display: flex;
    justify-content: center;
}
.image img{
    height:90px;
    width:90px;
align-items: center;
display: flex;
justify-content: center;
margin-top: 20%;
}
.mb-3{
    margin-bottom: 0rem!important;
}
// .modal-content{
//     border-radius:7%;
// }
.form-control{
    font-size:14px;
}
@media (min-width: 1400px){
.form {
     margin-left: 0px !important;
}
}
.tittle{
    font-size:22px!important;
}
.finance-friends img:hover{
    cursor: pointer;
 transform: scale(1.2);
transition:0.5s;
}
.tap-to-view{
    color:#f45a2f;
    align-items: center;
    display: flex;
    justify-content: center;
}

.leftarrow {
    animation: arrow 500ms infinite linear;
    animation-direction: alternate;
  }
  .uparrow{
    animation: arrowup 500ms infinite linear;
    animation-direction: alternate;  
  }
  @keyframes arrowup {
    0% {
      transform: translateY(-20px)
    }
  
    100% {
      transform: translateY(0px)
    }
  }
  
  @keyframes arrow {
    0% {
      transform: translateX(-20px)
    }
  
    100% {
      transform: translateX(0px)
    }
  }