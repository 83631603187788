.project-page-container {
    padding: 10px 0 30px 0;
    min-height: 70vh;
    display: flex;
    // align-items: center;
  .project-page-inner-container {
      .project-page-heading{
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 20px;
        color: #f45a2f;
        text-align: left;
      }
    
  }
}
@media (max-width:992px) {
  .project-page-heading{
    text-align: center!important;
  }
  
}