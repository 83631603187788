.publication-section{
  padding: 20px 0 60px;
  min-height: 80vh;
}

.publicationCard {
  display: flex;
  position: relative;
  overflow: hidden;
  .publication-image {
    width: 100%;
    height: 200px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in-out;
    transform: translateY(110%);
    background-color: rgba(0,0,0,0.8);
    .title{
      color: white;
      text-align: center;
      display: -webkit-box !important;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-transform: capitalize;
    }
  }
  &:hover{
    .mask{
      transform: translateY(0);
    }
  }
}


.publication-btnn {
  background-color: #f45a2f;
  color: white;
  border: 2px solid var(--web-theme);
  padding: 7px 10px 8px 10px;
  transition: 1s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  &:hover {
    background-color: #531401;
    border: 2px solid var(--web-theme);
    transition: 1s;
    color: white;
  }
}

.publication-description-inner{
  width: 100%;
  height: 400px;
}

.publication-container{
  min-height: 80vh;
}