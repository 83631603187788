.landing-about-section {
  // background: url("https://www.proflowers.com/blog/wp-content/uploads/2016/04/hero-white-flowers.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 0;
  position: relative;
  z-index: 0;
  .landing-about-inner-section {
    .landing-about-us-text {
      .landing-about-heading {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        color: white;
      }
      .about-us-detail {
        text-align: center;
        color: white;
        margin-bottom: 30px;
        font-size: 16px;
      }
      .about-us-button-container {
        display: flex;
        justify-content: center;
        .main-btnn {
          background-color: #f45a2f;
          color: white;
          border: 2px solid var(--web-theme);
          padding: 7px 10px 8px 10px;
          transition: 1s;
          display: inline-flex;
          width: 87px;
          justify-content: center;
          align-items: center;
          height: 40px;
          &:hover {
            background-color: #531401;
            border: 2px solid var(--web-theme);
            transition: 1s;
          }
        }
      }
    }
  }
}
.landing-about-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}
