.members-working-container {
}
.members-working-heading {
  padding-top: 10px;
  font-weight: 700;
  font-size: 32px;
  text-align: left;
  color: #f45a2f;
  text-align: center;
  margin-bottom: 50px;
}

.our-members-modal-container {
}
.our-member-modal-heading {
  padding-top: 10px;
  font-weight: 700;
  font-size: 32px;
  text-align: left;
  color: #f45a2f;
  text-align: center;
  margin-bottom: 50px;
  text-transform: capitalize;
}
.our-member-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
    height: 80%;
    object-fit: cover;
  }
}
.nepal-modal {
  .ant-modal-content {
    width: 60%;
    margin: auto;
    height: 90vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: rgb(179, 177, 177);
      border-radius: 10px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(136, 136, 136);
      border-radius: 10px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(100, 100, 100);
      border-radius: 10px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgb(68, 68, 68);
      border-radius: 10px;
      width: 5px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 90vh;
    }
  }
}

.members-list-container {
  width: 100%;
  .member-list-item {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.legend-box-container {
  position: absolute;
  right: 140px;
  top: 120px;
  @media (max-width: 992px) {
    position: absolute;
    right: 70px;
    top: 120px;
  }
}
.legend-box {
  display: flex;
  align-items: center;
}
.legend-text {
  font-size: 12px;
  margin-right: 10px;
  width: 60px;
}
.legend-color {
  height: 10px;
  width: 10px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
