.preloader-component svg {
    display:block;
    width:258px;
    height:258px;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
  }
  
  .tree {
    fill: #f45a2f;
  }
  
  .circle-mask {
    transform-origin: 50% 90%;
    animation: scale 5s infinite ease-out;
  }
  
  @keyframes scale {
    0%, 100% {
      transform: scale(0.0);
    }
    7%, 90% {
      transform: scale(0.4);
    }
    50% {
      transform: scale(1);
    }
  }

  .preloader-text{
    font-size: 20px;
    color: black;
    position: absolute;
    border: 10px;
    top: 62%;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    font-weight: 1000;
    font-family: "Nexa Bold";
  }