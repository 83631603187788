.contactFormSection {
  background-color: rgb(253, 253, 253);
  .contactForm {
    flex-direction: column;
    // align-items: center;
    padding: 30px 0;
    .inputSection {
      justify-content: space-around;
      width: 100%;
      display: flex;
      input {
        margin-bottom: 10px;
        padding: 8px 10px;
        font-size: 10px;
        border: 1px solid #f45a2f;

        border-radius: 5px;
        width: 100%;
      }
      input[type="text"]:focus {
        outline: none;
        border: 1.5px solid var(--web-theme); /* #f45a2fs! yey */
      }
      input[type="email"]:focus {
        outline: none;
        border: 1.5px solid var(--web-theme); /* #f45a2fs! yey */
      }
    }

    textarea {
      width: 100%;
      min-height: 200px;
      margin-top: 10px;
      padding-top: 10px;

      padding-left: 10px;
      border: 1px solid #f45a2f;
      border-radius: 5px;
    }
    .textAreazcont {
      width: 100%;
    }
    textarea[type="text"]:focus {
      outline: none;
      border: 1.5px solid var(--web-theme); /* #f45a2fs! yey */
    }
  }
}

.googleMap {
  position: relative;
}
iframe {
  z-index: 2;
  position: relative;
  padding-right: 10px;
}

.map-bg {
  position: absolute;
  background-color: #f45a2f;
  top: -55px;
  left: 425px;
  z-index: 1;
  width: 40%;
  height: 55%;
}
.map-container {
  // background-color: #f45a2f;
  // margin-top: -15%;
  /* margin: 10%; */
  // padding-right: 50px;
  // padding-top: 40px;
  /* width: 50vh; */
  // height: 50vh;
}
@media (max-width: 992px) {
  .map-bg {
    left: 200px;
    width: 60%;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .map-bg {
    left: 307px;
    width: 45%;
  }
}
@media (max-width: 992px) {
  .map-bg {
    left: 382px;
    width: 38%;
  }
}
.contact {
  background-image: url("../../assets/texture.png");
}
// hr{
//     height: 3px!important;
//     width: 20%;
//     background: #f45a2f;
//     opacity: 1;
// }
@media (max-width: 460px) {
  .map-bg {
    left: 336px;
    width: 38%;
    top: -30px;
    height: 27%;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .map-bg {
    left: 236px;
    width: 40%;
  }
}
@media (max-width: 768px) {
  .send-btn {
    margin-bottom: 10%;
  }
}
