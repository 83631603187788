.project-description-container {
  min-height: 100vh;
  .project-desc-inner {
    width: 75%;
    margin: auto;
  }
  .projectSection {
    .projectTitle {
      width: 100% !important;
      margin-bottom: 30px;
      h3 {
        text-align: left;
        font-weight: 700;
        color: #f45a2f;
        margin-bottom: 0px;
      }
    }

    h4 {
      margin-top: 10px;
      text-align: left;
      font-weight: 600;
    }
    p {
      margin-top: 30px;
      font-size: 15px;
    }
  }
}

.project-description-container {
  padding: 10px 0;
  .projectImgSection {
    margin-bottom: 30px;
    img {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
      margin: auto;
      display: flex;
      justify-content: center;
      aspect-ratio: 5/3;
    }
  }
  h6 {
    color: var(--web-theme);
    font-weight: 600;
    font-size: 24px;
    text-align: left;
  }
}
.project-paragraph {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .project-desc-inner {
    width: 100%!important;
    margin: auto;
  }
}
