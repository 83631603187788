:root{
  --web-theme:#F1592F;
}



.myScrollbar::-webkit-scrollbar {
  width: 3px;
}

.myScrollbar::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
  width: 5px;

}

.myScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
  width: 5px;

}


.main-btn{
  width:"auto"!important
}
.main-btnn{
  width:"auto"!important
}
.ant-image{
  width: 100%;
}