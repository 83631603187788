
  .aboutDetail {
    margin: 50px auto;
    text-align: start;
    h6 {
      color: var(--web-theme);
    }
    p {
      font-size: 15px;
    }
    h2 {
      font-size: 34px;

      font-weight: 700;
      margin-bottom: 20px;
    }
  }



.aboutPage-inner-row {
  width: 75%;
  margin: auto;
  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin: auto;
    display: flex;
    justify-content: center;
    aspect-ratio: 5/4;
  }
}

@media (max-width: 768px) {
    .aboutPage-inner-row {
      width: 100%!important;
      margin: auto;
    }
  }
  