.donationText{

    .whytodonate{
        h3{
          font-weight: 700;
          font-size: 38px ;
          text-align: start;
          color:#f45a2f;
          margin-bottom: 20px;
        }
        p{
            text-align: start;
        }
    }

   
}