.eventCarousel {
  padding-top: 0px;
  margin: 30px auto;
  h2 {
    padding-top: 10px;
    font-weight: 700;
    font-size: 32px;
    color: var(--web-theme);
  }
  .item {
    width: 85%;
    margin-right: auto;
    height: 185px;
    background-color: #dfd9d9;
    padding-top: 20px;
    cursor: pointer;

    .Carousel-img {
      width: 150px;
      height: 190px !important;
      padding-left: 20px;
      img {
        size: cover;
      }
    }
    .Carousel-detail {
      width: 90%;
      padding-left: 39px;

      .title {
        border-bottom: 1px dotted grey;
        margin-bottom: 20px;
        padding-bottom: 20px;

        h5 {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      padding-bottom: 50px;
    }
  }
  .item:hover {
    box-shadow: none;
  }
}

@media (max-width: 745px) {
  .Carousel-detail {
    padding-left: 10% !important;
  }
}

@media (max-width: 663px) {
  .Carousel-detail {
    padding-left: 2% !important;
  }
}
@media (max-width: 611px) {
  .Carousel-img {
    width: 120px !important;
  }
  .Carousel-detail {
    padding-left: 0 !important;
  }
}
@media (max-width: 600px) {
  .Carousel-img {
    width: 200px !important;
  }
  .Carousel-detail {
    padding-left: 0 !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 600px) {
  .Carousel-img {
    width: 150px !important;
    margin-left: 15% !important;
    max-height: 150px !important;
  }
  .Carousel-detail {
    //padding-left: 10% !important;
    margin-left: 0.25% !important;
  }
  .footer {
    padding-left: 10%;
  }
}
@media (max-width: 300px) {
  .Carousel-img {
    width: 150px !important;
    margin-left: 15% !important;
    max-height: 150px !important;
  }
  .Carousel-detail {
    //padding-left: 10% !important;
    margin-left: 0.5% !important;
  }
  .footer {
    padding-left: 5%;
  }
}
.w-100 {
  height: 83%;
  width: 115% !important;
  object-fit: cover;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1265px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .w-100 {
    height: 83%;
    width: 80% !important;
  }

  .eventCarousel .item .Carousel-detail {
    width: 90%;
    padding-left: 13px;
  }
}
@media (max-width: 500px) {
  .w-100 {
    width: 78% !important;
  }
  .eventCarousel .item .Carousel-img {
    padding-left: 0px;
  }
  .footer {
    margin-left: 1% !important;
    padding-left: 0px;
  }
}
.ongoing-event-row {
  height: 70%;

  // height:58vh;
  margin: auto;
  padding-top: 40px;
  // padding-bottom:40px;
  margin-bottom: 30px;
}

.ongoing-text {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
      padding-left: 0 !important;
      margin-top: 30px;
      h4{
       font-size: 32px!important; 
      }
  }
}
