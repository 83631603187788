.main-menu {
  color: black;
  margin-bottom: 0;
  border: none;
  width: 100%;

  .navBar {
    padding: 0;
    @media (max-width: 768px) {
      display: none;
    }
    .navbar {
      color: black;
      justify-content: flex-end;
      padding-top: 4px !important;
      padding-bottom: 0px !important;
      border: none;
      margin: 0;

      .nav-item {
        padding: 7px 0;
        margin: 0;
        .nav-link {
          color: black;
          font-size: 16px;
          font-weight: 500;
          border-bottom: 3px solid white;
          margin-bottom: 0 !important;
          padding-bottom: 13px;
          transition: 0.3s;
          cursor: pointer;
        }
        .nav-link:hover {
          color: var(--web-theme);
          border-bottom: 3px solid var(--web-theme);
          transition: 0.3s;
          background-color: white;
        }
        .nav-link {
          padding-left: 0px !important;
          padding-right: 0px !important;
          margin-right: 20px;
        }
        .nav-link:focus {
          color: var(--web-theme);
          border-bottom: 3px solid var(--web-theme);
          transition: 0.3s;
          background-color: white;
        }
        .nav-link:active {
          color: var(--web-theme) !important;
          border-bottom: 3px solid var(--web-theme) !important;
          transition: 0.3s;
          background-color: white;
        }
        .dropdown-menu {
          border: none;
          padding: 0!important;
        }
      }
    }
  }
}

.menu-bar .offcanvas-end .dropdown-menu {
  position: relative !important;
  margin: 0 13px !important;
}

.dropdown-menu[data-bs-popper] {
  top: inherit;
  left: 0;
  margin-top: 0 !important;
}

.header-nav .dropdown-toggle::after {
  display: none;
}
.header-nav .dropdown-item {
  transition: 0s;
  background-color: #f45a2f;
  color: white;
  font-size: 15px !important;
  font-weight: 400;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}
.header-nav .dropdown-item:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
.header-nav .dropdown-item:hover {
  background-color: white;
  color: black;
}
.header-nav .dropdown-item a:hover {
  color: black !important;
}

.header-nav .dropdown-item a {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  transition: 0.3s;
  cursor: pointer;
}

@media (max-width: 992px) {
  .navbar-expand-sm .navbar-collapse .header-nav {
    display: none !important;
  }

  .navbar-expand-sm .navbar-collapse {
    display: none !important;
  }
}
.navbar-toggler {
  display: none !important;
}
