.menu-bar {
  background: white;
  color: black;
  .menu-row {
    height: 115px;
    max-width: 100%;
    margin: auto;

    .logo-section {
      margin: 0;
      height: 100%;

      //box-sizing: border-box;

      img {
        background-color: white;
        padding: 0;
        object-fit: cover;
        width: 150px;
        margin-left: -21% !important;
        height: 100%;

        .img-fluid {
          margin-top: 0px !important;
        }
      }
    }
  }
  .info {
    .top-section {
      display: flex;
      justify-content: right;
      border-bottom: 1.2px lightgray dotted;
      padding: 15px 20px 10px 0;

      .mail {
        border-right: 2px solid grey;
        i {
          padding-right: 15px;
          padding-left: 15px;
        }
        .mailT {
          cursor: pointer;
          transition: 0.5s;
          font-size: 14px;
        }
        :hover {
          color: #f45a2f;

          transition: 0.5s;
        }
      }
      .contact {
        font-size: 15px;
        padding-left: 4%;
        align-items: center !important;
        i {
          padding-right: 12px;
        }
        :hover {
          color: #f45a2f;
        }
      }

      .social {
        justify-content: flex-end;
        display: flex;
        a {
          text-decoration: none;
          color: white;

          i {
            color: black;
            padding-left: 30px;
            cursor: pointer;
            font-size: 18px;
            transition: 0.5s;
          }
          :hover {
            color: var(--web-theme);
            transition: 0.5s;
          }
        }
      }
    }
    .below-section {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 1200px) {
  .info {
    .top-section {
      justify-content: left !important;
      padding: 15px 0px 10px 0 !important;

      .social {
        a {
          i {
            justify-content: space-between !important;
            padding: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .top-section {
    justify-content: right !important;
    padding: 15px 0px 10px 0 !important;
    // margin-left: 19px;
    //padding-right: 0px !important;
    .social {
      a {
        i {
          justify-content: space-between !important;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .top-section {
    display: none !important;
  }
  .header-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 850px) {
  .logo-section {
    img {
      height: 77% !important;
    }
  }
}
@media (max-width: 857px) {
  .logo-section {
    img {
      height: 83% !important;
    }
  }
}
@media (max-width: 768px) {
  .info {
    align-items: center;
    height: 100%;
    display: flex !important;
    justify-content: flex-end;
    margin-top: 2%;
    width: 58%;
    height: 67%;
  }
  .logo-section {
    width: 40%;
    height: 100%;
  }

  .menu-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (max-width: 350px) {
  .info {
    height: 80%;
  }
  .logo-section {
    height: 100%;
  }
  .menu-row {
    height: 75px !important;
  }
}
@media (max-width: 550px) {
  .info {
    height: 80%;
  }
  .logo-section {
    height: 105%;
    padding-top: 10px !important;
  }
  .menu-row {
    height: 97px !important;
  }
}
@media (max-width: 400px) {
  .info {
    height: 80%;
  }
  .logo-section {
    height: 100%;
    padding-top: 10px !important;
  }
  .menu-row {
    height: 91px !important;
  }
}

@media (max-width: 816px) and (min-width: 767px) {
  .menu-bar {
    max-height: 112px !important;
  }
}
@media (max-width: 768px) {
  .menu-bar .menu-row .logo-section img {
    width: 130px;
  }
}

.menu-bar .menu-toggle {
  display: none;
  font-size: 24px;
  text-align: right;
  cursor: pointer;
  justify-content: flex-end;
  @media (max-width: 992px) {
    display: flex;
  }
}
