.hero-slider {
  box-sizing: border-box;
  overflow: hidden;
  .hero-carousel {
    .sliderOverlay {
      height: calc(100vh - 115px);
      width: 100vw;
      overflow: hidden;
      position: relative;
      z-index: 1;
      @media (max-width: 550px) {
        height: calc(100vh - 97px)!important;
      }
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: -1;
      }
    }
    .owl-nav {
      .owl-prev {
        position: absolute;
        top: 50%;
        font-size: 70px;
        color: #929674;
        transform: translateY(-50%);
        left: 0px;
        font-weight: lighter;
      }
      .owl-next {
        position: absolute;
        top: 50%;
        font-size: 70px;
        color: #929674;
        transform: translateY(-50%);
        right: 0px;
        font-weight: lighter;
      }
    }
  }

  .carousel-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .active.carousel-item img {
    transform: scale(1.2);
    transition: all 2.5s linear;
    transition-delay: 500ms;
  }

  .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    margin-right: 4px;
    margin-left: 4px;
    display: none;
  }

  img {
    // opacity: 1;

    transition: 2s;
  }

  .content {
    position: absolute;

    top: 50%;
    transform: translateY(50%);
    width: 100%;
    color: #ffffff;
    z-index: 3;

    .content-box {
      h2 {
        font-size: 45px;
        font-weight: 700;
        color: #ffffff;
        line-height: 1.2;
        text-align: right;
        margin-bottom: 10px;
        text-transform: uppercase;
        opacity: 1;
        text-shadow: 1px 1px 3px black;
        margin-right: 15px;
      }
    }
  }
}
@media (max-width: 764px) {
  .carousel-item {
    height: calc(100vh - 0px);
  }
}
@media (max-width: 500px) {
  .myCarousel {
    max-height: auto !important;
    .carousel-indicators [data-bs-target] {
      width: 8px;
      height: 8px;
    }

    .content-box {
      h2 {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        //line-height: 1.2;
        text-align: right;
        right: 2%;

        position: absolute;
        text-transform: uppercase;
        opacity: 1;
        text-shadow: 1px 1px 3px black;
      }
    }
  }
}

.slider {
  position: relative;
  z-index: 1;
}

.hero-slider .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.hero-slider .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (max-width: 578px) {
  .hero-slider .carousel-control-next {
    display: none !important;
  }

  .hero-slider .carousel-control-prev {
    display: none !important;
  }
}
