.eventCard a {
  color: inherit;
  text-decoration: none !important;
}
.eventCard a:hover {
  color: initial;
  text-decoration: none !important;
}
.title h5 {
  /* font-weight: 600; */
  cursor: pointer;
  height: 49px;
  text-overflow: ellipsis;
  /* height: 77px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  display: -webkit-box !important;
  overflow: hidden !important;
  line-height: 1.5;
}
