.place-order {
  width: 100%;
  // height: 200%;
  // background-image: url("../../assets/donation.png");
  background-size: cover;
  background-attachment: fixed;
  //    background-position: 0 81.35px;
  background-position: center;
  padding: 3.725rem 0;
  opacity: 1;
  background-repeat: no-repeat;
  margin: 70px auto auto auto;
  background-color: black;
  position: relative;
  z-index: 1;

  .main-btnn {
    background-color: #f45a2f;
    color: white;
    border: 2px solid #f45a2f;
    padding: 7px 10px 8px 10px;
    transition: 1s;
    display: inline-flex;
  }
  .main-btnn:hover {
    background-color: rgb(83, 20, 1);
    border: 2px solid var(--web-theme);
    transition: 1s;
  }
}

.place-order::after {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.place-order {
  h1 {
    color: #fff;
    font-size: 41px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  p {
    color: white;
    z-index: 1;
    font-size: 18px;
    padding: 12px;
    margin-bottom: 50px;
  }
}

.txt {
  .myTxt {
    max-width: 90%;
    margin: auto;
    h2 {
      color: #fff;
      font-size: 41px!important;
      font-weight: bold;
      margin-bottom: 3px;
    }
    p {
      color: white;
      z-index: 1;
      font-size: 16px;
      padding: 12px;
      margin-bottom: 50px;
    }
  }
}

