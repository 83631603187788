.donatePage {
  text-align: center;
  padding: 30px;
  width: 100%;
  margin-top: 0;

  background-color: rgb(249, 247, 247);

  .FormSection {
    justify-content: center;
    display: flex;
    width: 100%;
    margin: 50px auto 10px auto;
  }

  .ArticleSection {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  input {
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 10px;
    border: 1px solid #f45a2f;
    border-radius: 5px;
    width: 100%;
  }
}


@media (min-width: 768px) {
    .FormSection{
        width: 70%!important;
    }
    .ArticleSection{
        width: 70%!important;
    }
    
}