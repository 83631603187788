.mainContent{
    margin-top: 0px;
    padding-top: 30px;
    margin-bottom: 30px;

    h2{
        margin:auto;
        padding-bottom: 35px;
        padding-top: 10px;
        color: #f45a2f;
        font-weight: 700;
        font-size: 32px;
        text-transform: capitalize;
    }
}
.Event-section{
    margin-bottom: 70px;
    
    h2{
        padding-bottom: 50px;
        padding-top: 60px;
        font-weight: 700;
        font-size: 44px;
        
       // font-style: italic;
        color: var(--web-theme);
        text-shadow: 1px 1px 5px lightpink;
    }

    .event-row{
        justify-content: space-around;
    }
            
    .event1{
        width: 100%;
        height: 220px;
        background-color: #F3F3F3;
        display: flex;
        padding-top: 30px;
        box-shadow: 1px 1px 6px grey;
        position: relative;
        cursor: pointer;
        transition: .5s;

        .carousel-indicators [data-bs-target] {
   
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-top: 15px;
            margin-right: 4px;
            margin-left: 4px;
            background-color: #f45a2f;
            
        }
        .Carousel-img{
            width: 150px;
            height: 190px !important;
            padding-left: 20px;
            img{
                size: cover;
            }
        }
        .Carousel-detail{
            width: 90%;
            padding-left: 20%;
           
            .title{
                border-bottom: 1px dotted grey;
                margin-bottom: 20px;
                padding-bottom: 20px;
                
                h5{
                    font-weight: bold;
                    font-size: 16px;
                   
                }
            }

        }
        .footer{
            display: flex;
            flex-direction: column;
            font-size: 12px;
            padding-bottom: 50px;
            
        }
       
    }
    .event1:hover{
        background-color: rgba(237, 88, 43, 0.09);
        box-shadow: none;
    }

    .event2{
        //width: 45%;
        height: 220px;
        background-image: url('https://www.voicesofwomenmedia.org/wp-content/uploads/2022/03/WEBBANNER_WIL_frontpage-01.jpg');
        background-size: contain;
        box-shadow: 1px 1px 6px grey;
       // margin-top: 97px;
       cursor: pointer;
    }
    .event2:hover{
        background-color: rgba(237, 88, 43, 0.09);
        box-shadow: none;
    }
    
}

@media(max-width:992px){
    .event-row{
        display: flex;
        flex-direction: column;
        .event1{
            width: 80%;
            height: 270px;
            margin-left: 10%;
            margin-right: 10%;

            .Carousel-img{
                width: 250px;
               
            }
        }
        .event2{
            margin-top: 20px;
            width: 80%;
            background-size: cover;
            margin-left: 10%;
            margin-right: 10%;
            height: 270px;
        }
    }
    // .title{
    //     margin-left: 24% ;
    // } 
    .footer{
        // margin-left: 25% ;
    }
}
@media(max-width:576px){
    .event-row{
       
        
        
       
        .event1{
            width: 95%;
            margin-left: 8px;
            margin-right: 5px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            height: 250px;
            padding: auto;
            

            .Carousel-img{
                width: 150px;
                height: 150px !important;
               // margin-left: 20%;

            }
            .Carousel-detail{
             width: 100% !important;
             margin-top: 0 !important;
             padding-left: 0px !important;
                .title{
                   // margin-left: 20%;
                    //margin-top: 10px;
                }
            }
            .footer{
               
            }
        }

        .event2{
            height: 200px;
            width: 95%;
        }
    }
}
@media(max-width:500px){
    .event-row{
        display: flex;
        flex-direction: column;

        .event1{
            width: 100%;
            margin-left: 8px;
            margin-right: 5px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            height: 450px;
            padding: auto;

            .Carousel-img{
                width: 190px;
                height: 190px !important;
                margin-left: 20%;

            }
            .Carousel-detail{
                .title{
                    //margin-left: 40%;
                    margin-top: 10px;
                }
            }
            .footer{
                margin-left: 38%;
            }
        }
        .event2{
            width: 95%;
            height: auto;
            margin-left: 8px;
            margin-right: 5px;
            margin-top: 10px;
        }
    }
}

@media(max-width:300px){
    .event-row{
        display: flex;
        flex-direction: column;

        .event1{
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            height: 450px;
            padding: auto;

            .Carousel-img{
                width: 190px;
                height: 190px !important;
                margin-left: 4%;

            }
            .Carousel-detail{
                .title{
                    margin-left: 27%;
                    margin-top: 10px;
                }
            }
            .footer{
                margin-left: 27%;
            }
        }
        .event2{
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 10px;
            //height: 450px;
        }
    }
}