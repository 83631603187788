// .prov-1 {
//     fill: #ef6f5a;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }
//   .prov-2 {
//     fill: #fde76e;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }
//   .prov-3 {
//     fill: #abc33a;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }
//   .prov-4 {
//     fill: #79cff2;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }
//   .prov-5 {
//     fill: #f45a2f;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }
//   .prov-6 {
//     fill: #e4a2c8;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }

//   .prov-7 {
//     fill: #8fc89f;
//     stroke: #000;
//     stroke-miterlimit: 10;
//   }

polygon {
  fill: white;
  stroke: #000;
  stroke-miterlimit: 10;
}

.fill-orange {
  fill: #f45a2f;
}
.fill-orange:hover{
  fill:#f45a2f
}