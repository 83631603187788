.announcementSection {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  object-fit: cover;
  margin-top: 0;
  background-color: rgb(249, 247, 247);

  h2 {
    padding-top: 30px;
    margin-bottom: 40px;
    color: var(--web-theme);
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }

  .imgSection {
    height: 500px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 40px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      size: cover;
    }
  }
}

@media (max-width: 600px) {
  .announcementSection {
    width: 100%;
    margin: 0px !important;
    padding-left: 2%;
    padding-right: 2%;

    .imgSection {
      height: 300px;
    }
  }
  h2 {
    font-size: 24px !important;
  }
}
.announcementCard {
  text-align: left;
  .Description {
    color: black;
  }
}
.announcementRow {
  margin-bottom: 3%;
  .announcementCard {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    &:hover {
      .announcement-overlay {
        transform: translateY(0);
      }
    }
    img {
      max-width: 100%;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: center;
    }
    .announcement-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      transform: translateY(100%);
      transition: all 500ms ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        color: #fff;
        font-size: 16px;
        text-transform: capitalize;
        text-align: center;

      }
    }
  }
}
