.forget-password{
    text-decoration: none;
    font-size: 14px;
    color:#f45a2f;
}
.new-acc{
    text-decoration: none;
    color: #f45a2f;
}
.new-account{
    /* margin-bottom: 25px; */
    margin-top: 25px;
}
.buttons{
    /* display: flex;
    justify-content: flex-end; */
    margin-bottom: 30px;
    margin-top: 10px;
}
.login-modal-container .modal-content{
    width: 330px !important;
    /* height:400px; */
    padding: 12px 0px 40px;
}
.login-into{
    margin-bottom: 25px;
    display: flex;
    color:#f45a2f;

    justify-content: flex-start;
    font-size: 21px;
}