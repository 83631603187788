.annualReport {
  background-color: rgb(249, 247, 247);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  h2 {
    padding-top: 30px;
    margin-bottom: 40px;
    color: var(--web-theme);
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }
}

.reportSection {
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 1px 1px 10px rgb(196, 195, 195);
  position: relative;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .projectTitle {
    h5 {
      font-weight: 600;
      cursor: pointer;
      margin: 15px auto 20px auto;
    }
  }
  .reportImage {
    .ant-image {
      position: relative;
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }
  .report-txt {
    position: absolute;
    bottom: 30%;
    color: white;
    width: 100%;
    transition: 0s;
    text-align: center;
    padding: 7px 5px;

    opacity: 0;
    z-index: 999;

    h5 {
      font-size: 32px;
      font-weight: 900;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }
  :hover {
    //    transform: scale(1);
  }
  .ant-image-mask {
    z-index: 9999;
    // cursor:none;
  }
  .ant-image-preview-wrap {
    z-index: 10000;
  }
}
.reportSection:hover .report-txt {
  bottom: 40%;
  opacity: 1;
  transition: all 500ms linear;
  transition-delay: 500ms;
  text-shadow: 1px 1px 8px grey;
}

.reportSection::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--web-theme);
  cursor: pointer;

  top: -100%;
  z-index: 1;
  opacity: 0.7;
  transform: matrix(1);
  transform-origin: center top 0;
  transition: 0.6s;
}
.reportSection:hover::before {
  top: -0%;
}
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.ant-message {
  z-index: 1060 !important;
}
