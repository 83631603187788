.announcement-detail-page {
  padding: 10px 0 60px;
  .announcement-detail-image {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    .ant-image {
      width: auto;
    }
    img {
      height: 350px;
      width: 250px;
      object-fit: contain;
    }
  }
  .announcement-detail-title {
    color: #f45a2f;
    font-size: 28px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .announcement-detail-desc {
    font-size: 14px;
  }
  .announcement-detail-button {
    a {
      background-color: #f45a2f;
      color: white;
      border: 2px solid var(--web-theme);
      padding: 7px 10px 8px 10px;
      transition: 1s;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      text-decoration: none;
    }
  }
}
