.article-container {
  text-align: left;
  margin: auto;
  padding-bottom: 50px;
}
.article-description-inner {
  width: 75%;
  margin: auto;
}
.article-description-inner img {
  width: 100%;
  height: auto !important;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  max-width: 100% !important;
  max-height: 350px;
  object-fit: contain;
}

.article-desc-detail img {
  width: 80% !important;
  margin: auto !important;
  max-height: 350px;
  object-fit: contain;
}

.article-desc-heading {
  color: #f45a2f;
  font-size: 28px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.article-desc-detail {
  font-size: 16px;
}

@media (max-width: 768px) {
  .article-description-inner {
    width: 100% !important;
    margin: auto;
  }
}
