.photo-tab-container {
}
.photo-gallery {
}
.gallery-image-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.gallery-image-container .ant-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
