.project-card-box {
  a {
    .project-card-container {
      height: 300px !important;
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-bottom: 20px;
      // margin-bottom: 10px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        box-sizing: content-box;
        transition: 500ms;
        // position: absolute;
        // transform: translate(-50%, -50%);
      }
      :hover {
        transition: 500ms;
        transform: scale(1.1);
        opacity: 0.9;
      }

      .project-txt {
        position: absolute;
        bottom: 30%;
        color: white;
        width: 100%;
        transition: 0s;
        text-align: center;
        padding: 7px 5px;
        font-size: 28px;
        font-weight: 900;
        opacity: 0;
        z-index: 999;
      }
      :hover {
        transform: scale(1);
      }
    }
    .project-card-container:hover .project-txt {
      bottom: 30%;
      opacity: 1;
      transition: all 500ms linear;
      transition-delay: 500ms;
      text-shadow: 1px 1px 8px grey;
    }
    .project-card-container::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 138.5%;
      background: var(--web-theme);
      cursor: pointer;

      top: -200%;
      z-index: 1;
      opacity: 0.7;
      transform: matrix(1);
      transform-origin: center top 0;
      transition: 0.6s;
    }
    .project-card-container:hover::before {
      top: -45%;
    }
  }
}
