.LoginWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
  }
  .LoginBox .ant-form-item-explain ant-form-item-explain-error {
    color: white;
  }
  
  .LoginBox h2 {
    padding: 0;
    margin: 0;
    color: white;
  }
  .LoginBox label.ant-form-item-required{
    color: white;
  }
  label.ant-checkbox-wrapper span{
    color: white
  }
  
  .buttonReverse {
    background: black;
    color: black;
    border: medium none;
    border: 1px black solid;
    padding: 10px 15px;
    cursor: pointer;
    height: auto;
    width: auto;
    font-weight: bold;
    font-size: 16px;
  
  }
  .buttonReverse:hover {
    color: black;
    background: rgb(246, 183, 69);
  }
  
  .LoginBox {
    border-radius: 20px;
    box-shadow: 3px 3px 5px black;
    padding: 50px 20px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    display: flex;
    flex-direction: column!important;
  }
  .LoginBox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
    border-color: black;
  }
  .LoginBox .ant-checkbox-inner:hover {
    border-color: black;
  }
  .admin-login{
    /* display: flex; */
    /* justify-content: center; */
    border: 2px solid #f45a2f;
    padding: 60px 10px;
    border-radius: 10px;
    /* box-shadow: 1px 1px 0px 1px grey; */

  }
  .admin-login .joinButton{
    width: auto!important;
  }