.case-container {
    text-align: left;
    margin: auto;
    padding-bottom: 50px;
  }
  .case-description-inner {
    width: 75%;
    margin: auto;
  }
  .case-description-inner img {
    width: 100%;
    height: auto!important;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    max-width: 100%!important;
    max-height: 350px!important;
    object-fit: contain;

  }
  
  .case-desc-heading {
      color: #f45a2f;
      font-size: 28px;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 20px;
  }
  .case-desc-detail {
      font-size: 16px;
   
  }
  .case-desc-detail img{
    width: 80%!important;
    margin: auto!important;
    object-fit: contain;
    max-height: 350px!important;
    max-width: 100%;
  }
  @media (max-width: 768px) {
    .case-description-inner {
      width: 100% !important;
      margin: auto;
    }
  }
  