.landing-page-hero {
  position: relative;
  .landing-page-inner-container {
    position: relative;
    .hero-section-text {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      height: 100%;
      .heroheading {
        font-size: 45px;
        color: #f45a2f;
        font-weight: 1000;
        text-transform: uppercase;
        text-align: left;
      }
      .herosubHeading {
        font-size: 18px;
        margin-bottom: 20px;
        text-align: left;
        color: #fff;
      }
      .main-btnn {
        background-color: #f45a2f;
        color: white;
        border: 2px solid #f45a2f;
        padding: 7px 10px 8px 10px;
        transition: 1s;
        display: inline-flex;
        width: 87px;
        justify-content: center;
        align-items: center;
        height: 40px;
        // margin-left: auto;
        // margin-right: auto;
        &:hover {
          background-color: #531401;
          border: 2px solid var(--web-theme);
          transition: 1s;
        }
      }
    }
    .hero-section-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // height: calc(100vh - 110px);
    }
  }
}

@media (max-width: 768px) {
  .heroheading {
    font-size: 25px!important;
  }
  .hero-text-con{
    width: 75%!important;
  }
}

@media (max-width: 448px) {
  .heroheading {
    font-size: 20px!important;
  }
  .hero-text-con{
    width: 75%!important;
  }
}
.hero-text-con{
  width: 50%;
}


